<template>
  <div class="page-content">
    <div class="card row">
      <div class="card-header">
        <div class="d-flex justify-content-between">
          <h6>Edit Chatbot Template</h6>
          <div>
            <button class="btn btn-primary float-right" @click="handlerSaveTemplate">{{ $t('chatbot_template.btn_save_new') }}</button>
          </div>
        </div>
      </div>
      <div class="card-body col-md-12">
        <b-row>
          <b-col md=8>
            <div id="header-content">
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm="8"
                content-cols-lg="9"
                label="Title">
                <el-input v-model="form.title" size="small" placeholder="Input template name" type="text"></el-input>
                <b-form-invalid-feedback :state="!v$.form.title.$invalid" v-if="v$.form.title.$errors[0]">
                  {{ v$.form.title.$errors[0].$message }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm="8"
                content-cols-lg="7"
                label="Keyword">
                <el-tag
                  v-for="(val, index) in form.keywords"
                  :key="index"
                  class="mr-2"
                  closable
                  :disable-transitions="false"
                  @close="handleClose(index)">
                    {{ val }}
                </el-tag>
                <el-input
                  class="input-new-tag"
                  v-if="input_keyword_visible"
                  v-model="input_keyword_value"
                  ref="saveTagInput"
                  size="mini"
                  @keyup.enter.native="handleInputConfirm"
                  @blur="handleInputConfirm">
                  </el-input>
                <el-button v-else class="button-new-tag" size="small" @click="handlerAddNewKeyword">+ New Keyword</el-button>
                <b-form-invalid-feedback :state="!(!form.keywords.length)" v-if="!form.keywords.length">
                  This field should be at least 1 keyword
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm="8"
                content-cols-lg="9"
                label="Scenario Type">
                <el-select @change="handlerChangeScenarioType" v-model="form.scenario_type" size="small">
                  <el-option v-for="(item, i) in scenario_type_options" :key="i" :label="item.text" :value="item.value"></el-option>
                </el-select>
              </b-form-group>
              <b-form-group
                v-if="form.scenario_type == 'cs'"
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm="8"
                content-cols-lg="9"
                label="Group Agent to Handle">
                <el-select v-model="form.target_agent_group" size="small" filterable :filter-method="handlerSearchGroup">
                  <el-option v-for="(item, i) in groupList" :key="i" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </b-form-group>
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm="8"
                content-cols-lg="9"
                label="Response Type">
                <el-select @change="handlerTypeChange" v-model="form.type" size="small">
                  <el-option v-for="(item, i) in template_type_options" :key="i" :label="item.label" :value="item.type"></el-option>
                </el-select>
              </b-form-group>
              <b-form-group
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm="8"
                content-cols-lg="9"
                v-if="form.type == 'interactive'"
                label="Header">
                <b-row>
                  <b-col md=12>
                    <el-select size="small" @change="handlerChangeHeaderType" v-model="form.header.type">
                      <el-option v-for="(item, i) in header_category" :key="i" :label="item.label" :value="item.type"/>
                    </el-select>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col>
                    <el-input v-if="form.header.type === 'text'"
                      size="small"
                      placeholder="Input header text"
                      v-model="form.header.content"
                      ref="inputHeaderContent"
                      maxlength="60"
                      show-word-limit />
                    <el-radio-group @change="handlerChangeMediaTypeHeader" v-model="form.header.content" v-if="form.header.type === 'media'">
                      <el-radio-button border v-for="item in header_media_type" :key="item.type" :label="item.type">
                        <font-awesome :icon="item.icon"/>&nbsp; {{ item.label }}
                      </el-radio-button>
                    </el-radio-group>
                    <b-form-invalid-feedback :state="!v$.form.header.content.$invalid" v-if="v$.form.header.content.$errors[0]">
                      {{ $t('validation.' + v$.form.header.content.$errors[0].$validator) }}
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group v-if="form.type == 'interactive' && form.header.type == 'media' && ['image','document','video'].includes(form.header.content)"
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm="8"
                content-cols-lg="9"
                label="Media Content">
                <b-file
                  v-loading="loading.upload_sample_media" class="mt-2"
                  :accept="whiteListExtensionHeaderMedia[form.header.content].join(',')"
                  @input="handlerFileHeaderMedia"
                  v-model="form.media_file"/>
                  <b-form-invalid-feedback :state="!v$.form.media_file.$invalid" v-if="v$.form.media_file.$errors[0]">
                    {{ $t('validation.' + v$.form.media_file.$errors[0].$validator) }}
                  </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group v-if="['image', 'video', 'document'].includes(form.type)"
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm="8"
                content-cols-lg="9"
                label="Caption">
                <el-input @focus="() => last_index_emoji = null" ref="input_caption" placeholder="Input message caption" type="textarea" rows="6" v-model="form.caption" show-word-limit :maxlength="form.type == 'text' ? 4096 : 1024"></el-input>
                <div class="float-right mt-2 mb-2">
                  <el-popover
                    placement="top"
                    width="380"
                    trigger="hover"
                  >
                    <picker @select="addEmoji($event, 'caption')" size="16" :showPreview="false" />
                    <span slot="reference">
                      <FeatherIcon type="smile" class="mr-2" style="width: 16px; margin-top: 5px; margin-bottom: -5px;"/>
                    </span>
                  </el-popover>
                </div>
              </b-form-group>
              <b-form-group v-if="['text', 'interactive'].includes(form.type)"
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm="8"
                content-cols-lg="9"
                label="Content">
                <el-input @focus="() => last_index_emoji = null" ref="input_content" placeholder="Input message content" type="textarea" rows="6" v-model="form.body" show-word-limit :maxlength="form.type == 'text' ? 4096 : 1024"></el-input>
                <div class="float-right mt-2 mb-2">
                  <el-popover
                    placement="top"
                    width="380"
                    trigger="hover"
                  >
                    <picker @select="addEmoji($event, 'content')" size="16" :showPreview="false" />
                    <span slot="reference">
                      <FeatherIcon type="smile" class="mr-2" style="width: 16px; margin-top: 5px; margin-bottom: -5px;"/>
                    </span>
                  </el-popover>
                </div>
                <b-form-invalid-feedback :state="!v$.form.body.$invalid" v-if="v$.form.body.$errors[0]">
                  {{ v$.form.body.$errors[0].$message }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group v-else
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm="8"
                content-cols-lg="9"
                label="Media Content">
                <b-file
                  v-loading="loading.upload_sample_media" class="mt-2"
                  :accept="whiteListExtensionHeaderMedia[form.type].join(',')"
                  @input="handlerFileHeaderMedia"
                  v-model="form.media_file"/>
                  <b-form-invalid-feedback :state="!v$.form.media_file.$invalid" v-if="v$.form.media_file.$errors[0]">
                    {{ $t('validation.' + v$.form.media_file.$errors[0].$validator) }}
                  </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                v-if="form.type == 'interactive'"
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm="8"
                content-cols-lg="9"
                label="Footer">
                <el-input show-word-limit maxlength="60" v-model="form.footer.content" size="small" placeholder="Input footer text" type="text"></el-input>
              </b-form-group>
              <b-form-group
                v-if="form.type == 'interactive'"
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="9"
                label="Button">
                <el-select @change="handlerButtonTypeChange" v-model="form.buttons.type" size="small">
                  <el-option v-for="(item, i) in button_type_options" :key="i" :label="item.label" :value="item.type"></el-option>
                </el-select>
              </b-form-group>
              <b-form-group
                v-if="form.type == 'interactive' && form.buttons.type == 'button'"
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="9"
                label="">
                <div class="inline inline-flex align-middle"
                  v-for="(item, i) in form.buttons.button_content" :key="i">
                  <el-input v-model="form.buttons.button_content[i].text"
                    class="mb-2 w-50 mr-2"
                    maxlength="20"
                    show-word-limit/>
                  <span>
                    <a @click="handlerRemoveQuickReply(i)" class="cursor-pointer"><font-awesome class="text-danger" style="font-size: 1.5em" icon="times-circle"/></a>
                  </span>
                </div>
                <el-button
                  @click="addButtonQuickReply"
                  :disabled="form.buttons.button_content.length === 3"
                  type="info"
                  size="small"
                  class="mt-2"><font-awesome icon="plus"/>&nbsp; Add Another Button
                </el-button>
              </b-form-group>
              <b-form-group
                v-if="form.type == 'interactive' && form.buttons.type == 'list'"
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="9"
                label="">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="3"
                  content-cols-sm
                  content-cols-lg="9"
                  label="Text Button">
                  <b-form-input maxlength="20" show-word-limit v-model="form.buttons.list_button_text" size="small" placeholder="Input button text" type="text"></b-form-input>
                  <b-form-invalid-feedback :state="!v$.form.buttons.list_button_text.$invalid" v-if="v$.form.buttons.list_button_text.$errors[0]">
                    {{ v$.form.buttons.list_button_text.$errors[0].$message }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <div v-for="(section, isection) in form.buttons.section_content" :key="isection">
                  <el-divider/>
                  <b-form-group
                    label-cols-sm="4"
                    label-cols-lg="3"
                    content-cols-sm
                    content-cols-lg="9"
                    label="Section Name">
                    <b-row>
                      <b-col>
                        <el-input maxlength="24" show-word-limit v-model="form.buttons.section_content[isection].title" size="small" placeholder="Input section name" type="text"></el-input>
                      </b-col>
                      <b-col v-if="form.buttons.section_content.length > 1" md="1">
                        <a @click="handlerRemoveSection(isection)" class="cursor-pointer"><font-awesome class="text-danger" style="font-size: 1.5em" icon="times-circle"/></a>
                      </b-col>
                    </b-row>
                    <!-- <b-form-invalid-feedback :state="!v$.form.buttons.section_content[isection].title.$invalid" v-if="v$.form.buttons.section_content[isection].title.$errors[0]">
                      {{ v$.form.buttons.section_content[isection].title.$errors[0].$message }}
                    </b-form-invalid-feedback> -->
                  </b-form-group>
                  <span v-for="(list, ilist) in form.buttons.section_content[isection].rows" :key="ilist">
                    <el-divider>{{ section.title }} Content - {{ (ilist + 1) }}</el-divider>
                    <b-row>
                      <b-col :md="form.buttons.section_content[isection].rows.length > 1 ? 5 : 6">
                        <el-input maxlength="24" show-word-limit v-model="list.title" size="small" placeholder="Input list title" type="text"></el-input>
                      </b-col>
                      <b-col md="6">
                        <el-input maxlength="72" show-word-limit v-model="list.description" size="small" placeholder="Input list description" type="textarea"></el-input>
                      </b-col>
                      <b-col md="1" v-if="form.buttons.section_content[isection].rows.length > 1">
                        <span>
                          <a @click="handlerRemoveList(ilist, isection)" class="cursor-pointer"><font-awesome class="text-danger" style="font-size: 1.5em" icon="times-circle"/></a>
                        </span>
                      </b-col>
                    </b-row>
                  </span>
                  <div class="row">
                    <div class="col-12">
                      <el-button
                        @click="addButtonList(isection)"
                        :disabled="computedListBtnLength > 9"
                        type="info"
                        size="small"
                        class="mt-2 float-right"><font-awesome icon="plus"/>&nbsp; Add More List
                      </el-button>
                    </div>
                  </div>
                </div>
                <el-button
                  @click="addSectionHandler"
                  :disabled="form.buttons.section_content.reduce((a, b) => a + b.rows.length , 0) > 9"
                  type="primary"
                  size="small"
                  class="mt-2"><font-awesome icon="plus"/>&nbsp; Add Section
                </el-button>
              </b-form-group>
              <b-form-group
                v-if="form.type == 'interactive' && form.buttons.type == 'cta_url'"
                label-cols-sm="4"
                label-cols-lg="2"
                content-cols-sm
                content-cols-lg="9"
                label="">
                <div class="row">
                  <div class="col-5">
                    <el-input placeholder="Button text" v-model="form.buttons.cta_url.display_text"
                      class="mb-2 mr-2"
                      maxlength="20"
                      show-word-limit/>
                    <b-form-invalid-feedback :state="!v$.form.buttons.cta_url.display_text.$invalid" v-if="v$.form.buttons.cta_url.display_text.$errors[0]">
                      {{ $t('validation.' + v$.form.buttons.cta_url.display_text.$errors[0].$validator) }}
                    </b-form-invalid-feedback>
                  </div>
                  <div class="col-7">
                    <el-input placeholder="URL" v-model="form.buttons.cta_url.url"
                      class="mb-2 mr-2"/>
                      <b-form-invalid-feedback :state="!v$.form.buttons.cta_url.url.$invalid" v-if="v$.form.buttons.cta_url.url.$errors[0]">
                      {{ $t('validation.' + v$.form.buttons.cta_url.url.$errors[0].$validator) }}
                    </b-form-invalid-feedback>
                  </div>
                </div>
              </b-form-group>
            </div>
            <div v-if="activeWorkspace.products[0].limit.api_integration_chatbot_keywords">
              <hr/>
              <h6>Integrations (Optional)</h6>
              <small>Trigger API call for every time customer reach this keyword.</small>
              <div class="mt-2">
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm="8"
                  content-cols-lg="9"
                  label="Method">
                  <el-input size="small" readonly value="POST"></el-input>
                </b-form-group>
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm="8"
                  content-cols-lg="9"
                  label="URL">
                  <el-input size="small" placeholder="https://example.com/trigger-keyword-webhook" v-model="form.trigger_request_payload.url"></el-input>
                  <b-form-invalid-feedback :state="!v$.form.trigger_request_payload.url.$invalid" v-if="v$.form.trigger_request_payload.url.$errors[0]">
                    {{ v$.form.trigger_request_payload.url.$errors[0].$message }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm="8"
                  content-cols-lg="9"
                  label="Headers">
                  <div class="row w-100" v-for="(item, i) in mapped_headers_integrations" :key="i">
                    <div style="display: flex; align-items: center;" class="w-100">
                      <div class="col-5">
                        <el-input v-model="item[0]"
                          class="mb-2"
                          maxlength="64"
                          show-word-limit/>
                      </div>
                      <div class="col-5">
                        <el-input v-model="item[1]" class="mb-2"/>
                      </div>
                      <a @click="handlerRemoveHeaderIntegration(i)" class="cursor-pointer"><font-awesome class="text-danger" style="font-size: 1.5em" icon="times-circle"/></a>
                    </div>
                  </div>
                  <el-button
                    @click="addHeaderParameter"
                    type="info"
                    size="small"
                    class="mt-2"><font-awesome icon="plus"/>&nbsp; Add Header Paramater
                  </el-button>
                </b-form-group>
                <b-form-group
                  label-cols-sm="4"
                  label-cols-lg="2"
                  content-cols-sm="8"
                  content-cols-lg="9"
                  label="Body">
                  <div class="list-group mb-2">
                    <div class="list-group-item w-50" style="font-family: 'Courier New'" v-for="(item, k) in payload_sent_lists" :key="k">
                      <p class="d-flex justify-content-between align-items-center">
                        {{ item.name }}
                        <el-popover
                          placement="right"
                          width="325"
                          :content="item.description"
                          trigger="hover">
                          {{ item.description }}
                          <span slot="reference" class="float-right">
                            <font-awesome icon="question-circle" class="text-info float-right"/>
                          </span>
                        </el-popover>
                      </p>
                    </div>
                  </div>
                  <div class="row w-100" v-for="(item, i) in mapped_body_integrations" :key="i">
                    <div style="display: flex; align-items: center;" class="w-100">
                      <div class="col-6">
                        <el-input v-model="item[0]"
                          class="mb-2"
                          placeholder="Parameter"
                          maxlength="64"
                          show-word-limit/>
                      </div>
                      <div class="col-5">
                        <el-input placeholder="Value" v-model="item[1]" class="mb-2" />
                      </div>
                      <a @click="handlerRemoveBodyIntegration(i)" class="cursor-pointer"><font-awesome class="text-danger" style="font-size: 1.5em" icon="times-circle"/></a>
                    </div>
                  </div>
                  <el-button
                    @click="addBodyParameter"
                    type="info"
                    size="small"
                    class="mt-2"><font-awesome icon="plus"/>&nbsp; Add Body Paramater
                  </el-button>
                </b-form-group>
              </div>
            </div>
          </b-col>
          <b-col md=4>
            <div class="screen">
              <div class="screen-container">
                <div class="chat">
                  <div class="chat-container">
                    <div class="conversation">
                      <div class="conversation-container vh-100">
                        <h6 class="mt-2">Preview</h6>
                        <div class="preview-message received w-100">
                          <!-- Header Text -->
                          <span class="mb-2" style="font-size: 14px; font-weight: bold" v-if="form.type == 'interactive' && form.header.type == 'text' && previewHeader">
                            {{ previewHeader }}<br/>
                          </span>
                          <!-- End of Header Text -->
                          <!-- Header Media -->
                          <div class="header-media-area mb-2" v-if="form.header.type === 'media' || ['image', 'video', 'document'].includes(form.type)">
                            <!-- <span v-if="form.header.content_base64">
                              <div v-if="form.header.content === 'image'" class="header-media-filled" :style="'background-image: url('+ (form.header.content || form.media_content) +')'"/>
                            </span>
                            <div v-else class="header-media-content" :style="'background-image: url('+ bg_media[(form.header.content || form.media_content)] +')'">
                            </div> -->
                            <!-- <div v-if="previewHeader && (form.header.type === 'image' || form.type === 'image')" class="header-media-filled" :style="{ -->
                            <div v-if="!form.media_file && !previewHeader" class="header-media-content" :style="'background-image: url('+ form.media_content +')'"></div>
                            <template v-else>
                              <div v-if="previewHeader && (form.type == 'image' || form.header.content == 'image')" class="header-media-filled" :style="{
                              'background-image': 'url('+ previewHeader +')',
                              }"/>
                              <video v-else-if="previewHeader && (form.type == 'video' || form.header.content == 'video')" class="header-media-filled" :src="previewHeader">
                              </video>
                              <div v-else class="header-media-content" :style="'background-image: url('+ bg_media.document +')'"></div>
                            </template>
                          </div>
                          <!-- End of Header Media -->
                          <!-- Main Content -->
                          <p class="preview-body" v-html="previewContent"/>
                          <!-- End of Main Content -->
                          <!-- Footer -->
                          <p class="mt-2 preview-footer" v-if="previewFooterContent">{{previewFooterContent}}<br/></p>
                          <!-- End of Footer -->
                          <span class="metadata"><span class="time">{{ moment().format('HH:mm') }}</span></span>
                          <div class="cta-area w-100" v-if="form.type === 'interactive' && form.buttons.type === 'list'">
                            <div class="cta-button">
                              <p><font-awesome icon="list"/>&nbsp;{{ form.buttons.list_button_text }}</p>
                            </div>
                          </div>
                          <div class="cta-area w-100" v-if="form.type == 'interactive' && form.buttons.type === 'cta_url'">
                            <div class="cta-button">
                              <p>
                                <font-awesome icon="external-link-alt"/>
                                &nbsp;{{ form.buttons.cta_url.display_text }}</p>
                            </div>
                          </div>
                        </div>
                        <div class="button-area w-100" style="margin-top: -1em" v-if="form.buttons.type === 'button' && form.type == 'interactive'">
                          <div class="row-button">
                            <div class="col-button" v-for="(item, i) in form.buttons.button_content" :key="i">
                              {{ item.text }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
import { uniq, isEmpty, uniqBy } from 'lodash';
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import { required, requiredIf, url } from '@vuelidate/validators';
import { Picker } from 'emoji-mart-vue-2';

import groupsAPI from '../../../api/agentGroups';
import base64File from '../../../library/base64File';
import chatbotTemplates from '../../../api/chatbotTemplates';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  metaInfo: {
    title: 'Chatbot Templates',
  },
  name: 'ChatbotTemplatesCreate',
  components: {
    Picker,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        title: { required },
        body: { required: requiredIf(['text', 'interactive'].includes(this.form.type)) },
        media_file: { required: requiredIf(['image', 'document', 'video'].includes(this.form.type)) && !this.form.media_content },
        header: {
          content: { required: requiredIf(this.form.type === 'interactive' && this.form.header.type === 'text') },
        },
        buttons: {
          list_button_text: { required: requiredIf(this.form.type === 'interactive' && this.form.buttons.type === 'list') },
          cta_url: {
            display_text: {
              required: requiredIf(this.form.buttons.type === 'cta_url'),
            },
            url: {
              required: requiredIf(this.form.buttons.type === 'cta_url'),
              url,
            },
          },
        },
        target_agent_group: { required: requiredIf(this.form.scenario_type === 'cs') },
        trigger_request_payload: {
          url: {
            url,
          },
        },
      },
    };
  },
  data() {
    return {
      moment,
      form: {
        type: 'text',
        title: '',
        keywords: [],
        body: '',
        caption: '',
        media_content: '',
        media_file: null,
        header: {},
        buttons: {
          type: 'none',
          button_content: [{ text: '' }],
          list_content: [{ title: '', description: '' }],
          list_button_text: '',
          list_button_section_name: '',
          section_content: [{
            title: '',
            rows: [{ title: '', description: '' }],
          }],
          cta_url: {
            display_text: '',
            url: '',
          },
        },
        footer: {
          content: '',
        },
        scenario_type: 'normal',
        target_agent_group: '',
        trigger_request_payload: {
          method: 'post',
        },
      },
      payload_sent_lists: [
        {
          name: 'from',
          description: 'User phone number/identifier. Example: 682134567890',
        },
        {
          name: 'from_name',
          description: 'User profile name. Example: John Doe',
        },
        {
          name: 'type',
          description: 'Define the type of the message.',
        },
        {
          name: 'msg_id',
          description: 'Message ID.',
        },
        {
          name: 'message',
          description: 'Message content.',
        },
        {
          name: 'media_id',
          description: 'ID of the media if the type is image, video or document. Default null.',
        },
        {
          name: 'media_url',
          description: 'Media URL. Default null.',
        },
        {
          name: 'mime_type',
          description: 'Mime type of the media. Example: image/png. Default null.',
        },
        {
          name: 'caption',
          description: 'Caption of the message. Default null. Will be filled if type is media (image, video)',
        },
        {
          name: 'ori_timestamp',
          description: 'UNIX timestamp when message is received.',
        },
      ],
      scenario_type_options: [
        {
          value: 'normal',
          text: 'Normal',
        },
        {
          value: 'cs',
          text: 'Customer Service',
        },
      ],
      bg_media: {
        image: 'https://api.media.makna.ai/makna-media/template-header-image.png',
        document: 'https://api.media.makna.ai/makna-media/template-header-document.png',
        video: 'https://api.media.makna.ai/makna-media/template-header-video.png',
      },
      template_type_options: [
        {
          type: 'text',
          label: 'Text',
        },
        {
          type: 'image',
          label: 'Image',
        },
        {
          type: 'video',
          label: 'Video',
        },
        {
          type: 'document',
          label: 'Document',
        },
        {
          type: 'interactive',
          label: 'Interactive',
        },
      ],
      header_category: [
        {
          type: 'none',
          label: 'None',
        },
        {
          type: 'text',
          label: 'Text',
        },
        {
          type: 'media',
          label: 'Media',
        },
      ],
      header_media_type: [
        {
          type: 'image',
          label: 'Image',
          icon: 'image',
        },
        {
          type: 'video',
          label: 'Video',
          icon: 'video',
        },
        {
          type: 'document',
          label: 'Document',
          icon: 'file-alt',
        },
      ],
      button_type_options: [
        {
          type: 'button',
          label: 'Button',
        },
        {
          type: 'list',
          label: 'List',
        },
        {
          type: 'cta_url',
          label: 'URL Button',
        },
      ],
      whiteListExtensionHeaderMedia: {
        image: ['.jpg', '.png'],
        video: ['.mp4'],
        document: ['.pdf'],
      },
      loading: {
        upload_sample_media: false,
      },
      input_keyword_visible: false,
      input_keyword_value: '',
      template: {},
      groupList: [],
      last_index_emoji: null,
      mapped_headers_integrations: [],
      mapped_body_integrations: [],
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    previewHeader() {
      if (this.form.type === 'interactive') {
        if (this.form.header.type === 'text') {
          return this.form.header.content;
        }
        return this.form.media_content;
      }
      if (['image', 'document', 'video'].includes(this.form.type) || ['image', 'document', 'video'].includes(this.form.header.type)) {
        return this.form.media_content;
      }
      return null;
    },
    previewContent() {
      let string = ['text', 'interactive'].includes(this.form.type) ? this.form.body : null;
      if (['image', 'video', 'document'].includes(this.form.type)) {
        string = this.form.caption;
      }
      if (!string) {
        return null;
      }
      const htmlFormat = [
        { symbol: '*', tag: 'b' },
        { symbol: '_', tag: 'em' },
        { symbol: '~', tag: 'del' },
        { symbol: '```', tag: 'tt' },
        { symbol: '`', tag: 'mark' },
      ];

      htmlFormat.forEach(({ symbol, tag }) => {
        if (!string) return;
        const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, 'gm');
        const match = string.match(regex);
        if (!match) return;
        match.forEach((m) => {
          let formatted = m;
          /* eslint-disable no-plusplus */
          for (let i = 0; i < 2; i++) {
            formatted = formatted.replace(symbol, `<${i > 0 ? '/' : ''}${tag}>`);
          }
          string = string.replace(m, formatted);
        });
      });
      return string;
    },
    previewFooterContent() {
      return this.form.footer.content;
    },
    computedListBtnLength() {
      let length = 0;
      this.form.buttons.section_content.forEach((val) => {
        length += val.rows.length;
      });
      return length;
    },
  },
  mounted() {
    this.getDetail();
    this.v$.form.$touch();
    this.getGroupList();
  },
  methods: {
    addHeaderParameter() {
      this.mapped_headers_integrations.push(['', '']);
    },
    addBodyParameter() {
      this.mapped_body_integrations.push(['', '']);
    },
    async getGroupList(search = '') {
      await groupsAPI.getList(this.activeWorkspace._id, 1, search)
        .then((res) => {
          this.totalRow = res.data.total;
          if (this.form.target_agent_group) {
            this.groupList = this.groupList.filter((v) => v.id === this.form.target_agent_group);
          }
          this.groupList.push(...res.data.rows);
          this.groupList = uniqBy(this.groupList, 'id');
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getDetail() {
      this.$parent.showLoader();
      const response = await chatbotTemplates.detail({
        workspace_id: this.activeWorkspace._id,
        id: this.$route.params.id,
      });
      this.$parent.hideLoader();
      const { data } = response;
      const template = data.chatbot_keyword;
      this.template = template;
      if (!template) {
        return;
      }
      this.form = {
        type: template.body_type,
        title: template.title,
        keywords: template.keyword,
        body: template.body,
        caption: template.caption,
        media_content: template.body,
        media_file: null,
        header: {
          type: 'none',
        },
        buttons: {
          type: 'button',
          button_content: [{ text: '' }],
          list_content: [],
          section_content: [{
            title: '',
            rows: [{ title: '', description: '' }],
          }],
          cta_url: {
            display_text: '',
            url: '',
          },
        },
        footer: {
          content: '',
        },
        scenario_type: template.scenario_type || 'normal',
        target_agent_group: template.target_agent_group || '',
        trigger_request_payload: template.trigger_request_payload || {},
      };
      if (this.form.trigger_request_payload.headers) {
        Object.keys(template.trigger_request_payload.headers).forEach((key) => {
          this.mapped_headers_integrations.push([key, template.trigger_request_payload[key]]);
        });
      }
      if (!isEmpty(template.header)) {
        const header_type = template.header.type;
        this.form.header = {
          type: header_type === 'text' ? 'text' : 'media',
          content: header_type === 'text' ? template.header.text : header_type,
        };
        if (header_type !== 'text') {
          this.form.media_content = template.header[header_type].link;
        }
        this.handlerChangeHeaderType(this.form.header.type);
      }
      if (!isEmpty(template.footer)) {
        this.form.footer = {
          content: template.footer.text,
        };
      }
      if (template.action_type === 'button') {
        this.form.buttons.type = 'button';
        this.form.buttons.button_content = template.action.buttons.map((v) => ({ text: v.reply.title }));
      }
      if (template.action_type === 'list') {
        this.form.buttons.type = 'list';
        this.form.buttons.list_content = template.action.sections[0].rows;
        this.form.buttons.list_button_text = template.action.button;
        this.form.buttons.list_button_section_name = template.action.sections[0].title;
        this.form.buttons.section_content = template.action.sections;
      }
      if (template.action_type === 'cta_url') {
        this.form.buttons.type = 'cta_url';
        this.form.buttons.cta_url = {
          display_text: template.action.parameters.display_text,
          url: template.action.parameters.url,
        };
      }

      if (['image', 'document', 'video'].includes(this.form.type)) {
        this.form.body = template.caption;
        this.form.caption = template.caption;
      }
      await popupErrorMessages(response);
    },
    async handlerFileHeaderMedia() {
      if (!this.form.media_file) {
        this.form.media_content = '';
      }
      this.form.media_content = await base64File(this.form.media_file);
      if (this.form.type === 'document' || (this.form.type === 'interactive' && this.form.header.content === 'document')) {
        this.form.filename = this.form.media_file.name;
      }
    },
    async handleInputConfirm() {
      if (this.input_keyword_value) {
        await chatbotTemplates.validateKeyword({
          workspace_id: this.activeWorkspace._id,
          keyword: this.input_keyword_value,
        }).then((response) => {
          if (!response.error) {
            this.form.keywords.push(this.input_keyword_value);
            this.form.keywords = uniq(this.form.keywords);
          }
          if (response.error && response.error.title === 'duplicate_keyword') {
            this.$message({
              type: 'warning',
              message: `Keyword ${this.input_keyword_value} has been registered.`,
            });
          }
        });
      }
      this.input_keyword_value = '';
      this.input_keyword_visible = false;
    },
    handleClose(index) {
      this.form.keywords.splice(index, 1);
    },
    handlerAddNewKeyword() {
      this.input_keyword_visible = !this.input_keyword_visible;
      this.$nextTick(() => {
        this.$refs.saveTagInput.focus();
      });
    },
    handlerTypeChange(value) {
      if (['image', 'document', 'video'].includes(value)) {
        // if (this.form.body) this.form.caption = this.form.body;
        if (this.form.body) this.$set(this.form, 'caption', this.form.body);
        this.form.body = '';
        this.form.media_content = this.bg_media[value];
      } else if (value === 'interactive') {
        this.form.header = {
          type: 'none',
          content: '',
        };
      } else {
        if (this.form.caption) this.form.body = this.form.caption;
        this.form.caption = '';
      }
    },
    handlerChangeHeaderType(value) {
      if (value === 'media') {
        if (['image', 'video', 'document'].includes(this.form.header.content)) this.form.header.content = 'image';
        this.button_type_options = [
          {
            type: 'button',
            label: 'Button',
          },
        ];
        this.form.buttons.type = 'button';
      } else {
        this.button_type_options = [
          {
            type: 'button',
            label: 'Button',
          },
          {
            type: 'list',
            label: 'List',
          },
          {
            type: 'cta_url',
            label: 'URL Button',
          },
        ];
      }
      if (['image', 'video', 'document'].includes(this.form.header.content) && value !== 'media') this.form.header.content = '';
    },
    handlerChangeMediaTypeHeader(value) {
      if (['image', 'document', 'video'].includes(value)) {
        this.form.media_content = this.bg_media[value];
      }
    },
    async handlerSaveTemplate() {
      this.v$.form.$touch();
      if (this.v$.form.$error) return;

      const { form } = this;
      let body = {};
      if (form.type === 'text') {
        body = {
          type: form.type,
          title: form.title,
          keywords: form.keywords,
          body: form.body,
        };
      } else if (['image', 'video', 'document'].includes(form.type)) {
        body = {
          type: form.type,
          title: form.title,
          keywords: form.keywords,
          body: form.media_content,
          caption: form.caption,
        };
        if (form.type === 'document') {
          body.filename = form.filename;
        }
      } else if (form.type === 'interactive') {
        body = {
          type: form.type,
          title: form.title,
          keywords: form.keywords,
          body: form.body,
          caption: form.body,
        };
        if (!isEmpty(form.header)) {
          body.header = {
            content_type: form.header.type === 'text' ? form.header.type : form.header.content,
            content: form.header.type === 'text' ? form.header.content : form.media_content,
          };
          if (form.filename && form.header.content === 'document') {
            body.header.filename = form.filename;
          }
          if (body.header.content_type === 'document') {
            body.header.filename = form.media_file.name;
          }
        }
        if (!isEmpty(form.footer)) {
          body.footer = {
            text: form.footer.content,
          };
        }
        if (form.buttons.type === 'button') {
          body.action_type = 'button';
          body.action = {
            buttons: form.buttons.button_content.map((v, index) => ({
              type: 'reply',
              reply: {
                id: self.crypto.randomUUID(),
                title: v.text.trim(),
              },
            })),
          };
        } else if (form.buttons.type === 'list') {
          body.action_type = 'list';
          body.action = {
            button: form.buttons.list_button_text,
            sections: form.buttons.section_content.map((v1) => {
              v1.rows = v1.rows.map((v, index) => ({
                id: self.crypto.randomUUID(),
                title: v.title,
                description: v.description,
              }));
              return v1;
            }),
          };
          console.log('after mapping', body.action);
        } else if (form.buttons.type === 'cta_url') {
          body.action_type = 'cta_url';
          body.action = {
            name: 'cta_url',
            parameters: {
              display_text: form.buttons.cta_url.display_text,
              url: form.buttons.cta_url.url,
            },
          };
        }
      }
      body.scenario_type = form.scenario_type;
      body.target_agent_group = form.target_agent_group;
      if (this.mapped_headers_integrations.length) {
        this.mapped_headers_integrations.forEach((val) => {
          const { 0: key, 1: value } = val;
          if (key && value) {
            if (!form.trigger_request_payload.headers) {
              form.trigger_request_payload.headers = {};
            }
            form.trigger_request_payload.headers[key] = value;
          }
        });
      }
      if (this.mapped_body_integrations.length) {
        this.mapped_body_integrations.forEach((val) => {
          const { 0: key, 1: value } = val;
          if (key && value) {
            if (!form.trigger_request_payload.data) {
              form.trigger_request_payload.data = {};
            }
            form.trigger_request_payload.data[key] = value;
          }
        });
      }
      body.trigger_request_payload = form.trigger_request_payload;
      if (!body.trigger_request_payload.method || body.trigger_request_payload.method !== 'post') {
        body.trigger_request_payload.method = 'post';
      }
      this.$parent.showLoader();
      body.workspace_id = this.activeWorkspace._id;
      body.id = this.$route.params.id;
      const response = await chatbotTemplates.update(body);
      this.$parent.hideLoader();
      await popupErrorMessages(response).then(() => {
        this.$message({
          message: this.$t('chatbot_template.success_add'),
          type: 'success',
        });
        this.$router.push('/chatbot-templates');
      });
    },
    handlerButtonTypeChange() {
      if (this.form.buttons.type === 'button' && !this.form.buttons.button_content) {
        if (this.form.buttons.button_content) {
          this.form.buttons.button_content.push({ text: '' });
        } else {
          this.$set(this.form.buttons, 'button_content', [{ text: '' }]);
        }
      }
      if (this.form.buttons.type === 'list' && !this.form.buttons.section_content) {
        this.$set(this.form.buttons, 'section_content', [{
          title: '',
          rows: [{ title: '', description: '' }],
        }]);
      }
    },
    handlerRemoveQuickReply(index) {
      this.form.buttons.button_content.splice(index, 1);
      if (!this.form.buttons.button_content.length) {
        this.form.buttons.type = 'none';
      }
    },
    handlerRemoveHeaderIntegration(index) {
      this.mapped_headers_integrations.splice(index, 1);
    },
    handlerRemoveBodyIntegration(index) {
      this.mapped_body_integrations.splice(index, 1);
    },
    addButtonQuickReply() {
      this.form.buttons.button_content.push({ text: '' });
    },
    addButtonList(index) {
      this.form.buttons.section_content[index].rows.push({ title: '', description: '' });
    },
    handlerRemoveList(index, index_section) {
      this.form.buttons.section_content[index_section].rows.splice(index, 1);
    },
    handlerChangeScenarioType(value) {
      if (value === 'cs' && !this.form.target_agent_group) {
        this.form.target_agent_group = this.groupList[0].id;
      }
    },
    handlerSearchGroup(value) {
      clearTimeout(this.timeout_search_group);
      this.timeout_search_group = setTimeout(() => {
        this.getGroupList(value);
      }, 1000);
    },
    addSectionHandler() {
      this.form.buttons.section_content.push({
        title: '',
        rows: [{ title: '', description: '' }],
      });
    },
    addEmoji(emoji, type) {
      if (type === 'caption') {
        const pos = this.last_index_emoji !== null ? this.last_index_emoji : this.$refs.input_caption.$refs.textarea.selectionStart;
        if (this.last_index_emoji === null) this.last_index_emoji = pos;
        const start_str = this.form.caption.substring(0, pos);
        const end_str = this.form.caption.substring(pos, this.form.caption.length);
        this.form.caption = [start_str, emoji.native, end_str].join('');
      } else if (type === 'content') {
        const pos = this.last_index_emoji !== null ? this.last_index_emoji : this.$refs.input_content.$refs.textarea.selectionStart;
        if (this.last_index_emoji === null) this.last_index_emoji = pos;
        const start_str = this.form.body.substring(0, pos);
        const end_str = this.form.body.substring(pos, this.form.body.length);
        this.form.body = [start_str, emoji.native, end_str].join('');
      }
    },
    handlerRemoveSection(index) {
      this.form.buttons.section_content.splice(index, 1);
    },
  },
};
</script>
<style scoped>
.screen {
  text-align: left;
}
.screen-container {
  height: 100%;
}

/* Status Bar */

.status-bar {
  height: 25px;
  background: #004e45;
  color: #fff;
  font-size: 14px;
  padding: 0 8px;
}

.status-bar:after {
  content: "";
  display: table;
  clear: both;
}

.status-bar div {
  float: right;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 0 0 8px;
  font-weight: 600;
}

/* Chat */

.chat {
  height: calc(100% - 69px);
}

.chat-container {
  height: 100%;
}

/* User Bar */

.user-bar {
  height: 55px;
  background: #005e54;
  color: #fff;
  padding: 0 8px;
  font-size: 24px;
  position: relative;
  z-index: 1;
}

.user-bar:after {
  content: "";
  display: table;
  clear: both;
}

.user-bar div {
  float: left;
  transform: translateY(-50%);
  position: relative;
  top: 50%;
}

.user-bar .actions {
  float: right;
  margin: 0 0 0 20px;
}

.user-bar .actions.more {
  margin: 0 12px 0 32px;
}

.user-bar .actions.attachment {
  margin: 0 0 0 30px;
}

.user-bar .actions.attachment i {
  display: block;
  transform: rotate(-45deg);
}

.user-bar .avatar {
  margin: 0 0 0 5px;
  width: 36px;
  height: 36px;
}

.user-bar .avatar img {
  border-radius: 50%;
  /* box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1); */
  display: block;
  width: 100%;
}

.user-bar .name {
  font-size: 17px;
  font-weight: 600;
  text-overflow: ellipsis;
  letter-spacing: 0.3px;
  margin: 0 0 0 8px;
  overflow: hidden;
  white-space: nowrap;
  width: 110px;
}

.user-bar .status {
  display: block;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
}

/* Conversation */

.conversation {
  height: calc(100% - 12px);
  position: relative;
  background: #efe7dd url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg") repeat;
  z-index: 0;
}

.conversation ::-webkit-scrollbar {
  transition: all .5s;
  width: 5px;
  height: 1px;
  z-index: 10;
}

.conversation ::-webkit-scrollbar-track {
  background: transparent;
}

.conversation ::-webkit-scrollbar-thumb {
  background: #b3ada7;
}

.conversation .conversation-container {
  height: calc(100% - 68px);
  /* box-shadow: inset 0 10px 10px -10px #000000; */
  overflow-x: hidden;
  padding: 0 16px;
  margin-bottom: 5px;
}

.conversation .conversation-container:after {
  content: "";
  display: table;
  clear: both;
}

/* Messages */

.preview-message {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  /* max-width: 85%; */
  word-wrap: break-word;
  z-index: -1;
}

.preview-message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

.button-area {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px 0px;
  position: relative;
  word-wrap: break-word;
  z-index: -1;
  border-radius: 5px;
  float: left;
}

.reply-button {
  color: #5591EC;
  clear: both;
  line-height: 18px;
  font-size: 12px;
  padding: 8px;
  position: relative;
  word-wrap: break-word;
  z-index: -1;
  background: #fff;
  border-radius: 5px;
  float: left;
  text-align: center;
}

.cta-area {
  border-top: 1px solid #dadde1;
  /* margin: 0 7px 0 9px; */
  margin-top: 8px;
  float: right;
  background: #fff;
}

.cta-button {
  color: #00a5f4;
  font-size: 12px;
  height: 24px;
  line-height: 20px;
  padding: 0 16px;
  white-space: pre-wrap;
  text-align: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
  word-wrap: break-word;
  margin-top: 5%;
}

.header-media-area {
  padding: 3px 3px 0 3px;
}

.header-media-content {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 80px 80px;
  border-radius: 4px;
  box-sizing: border-box;
}

.header-media-content::after {
  content: '';
  display: block;
  padding-top: calc(100% * (1 / 1.91));
}

.header-media-image {
  background-image: url('https://api.media.makna.ai/makna-media/template-header-image.png');
}

.header-media-filled {
  background-color: #ccd0d5;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto 100%;
  /* background-size: cover; */
  border-radius: 4px;
  box-sizing: border-box;
  /* width: 240px; */
}

.header-media-filled::after {
  content: '';
  display: block;
  padding-top: calc(100% * (1 / 1.91));
}

.metadata {
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}

.metadata .time {
  color: rgba(0, 0, 0, .45);
  font-size: 11px;
  display: inline-block;
}

.metadata .tick {
  display: inline-block;
  margin-left: 2px;
  position: relative;
  top: 4px;
  height: 16px;
  width: 16px;
}

.metadata .tick svg {
  position: absolute;
  transition: .5s ease-in-out;
}

.metadata .tick svg:first-child {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(180deg);
          transform: perspective(800px) rotateY(180deg);
}

.metadata .tick svg:last-child {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(0deg);
          transform: perspective(800px) rotateY(0deg);
}

.metadata .tick-animation svg:first-child {
  -webkit-transform: perspective(800px) rotateY(0);
          transform: perspective(800px) rotateY(0);
}

.metadata .tick-animation svg:last-child {
  -webkit-transform: perspective(800px) rotateY(-179.9deg);
          transform: perspective(800px) rotateY(-179.9deg);
}

.preview-message:first-child {
  margin: 16px 0 8px;
}

.preview-message.received {
  background: #fff;
  border-radius: 0px 5px 5px 5px;
  float: left;
}

.preview-message.received .metadata {
  padding: 0 0 0 16px;
}

.preview-message.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -10px;
}

.preview-message.sent {
  background: #e1ffc7;
  border-radius: 5px 0px 5px 5px;
  float: right;
}

.preview-message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

/* Compose */

.conversation-compose {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
  height: 50px;
  width: 100%;
  z-index: 2;
}

.conversation-compose div,
.conversation-compose input {
  background: #fff;
  height: 100%;
}

.conversation-compose .emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 5px 0 0 5px;
  flex: 0 0 auto;
  margin-left: 8px;
  width: 48px;
}

.conversation-compose .input-msg {
  border: 0;
  flex: 1 1 auto;
  font-size: 16px;
  margin: 0;
  outline: none;
  min-width: 50px;
}

.conversation-compose .photo {
  flex: 0 0 auto;
  border-radius: 0 0 5px 0;
  text-align: center;
  position: relative;
  width: 48px;
}

.conversation-compose .photo:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #fff;
  border-style: solid;
  position: absolute;
  width: 0;
  height: 0;
  content: "";
  top: 0;
  right: -10px;
}

.conversation-compose .photo i {
  display: block;
  color: #7d8488;
  font-size: 24px;
  transform: translate(-50%, -50%);
  position: relative;
  top: 50%;
  left: 50%;
}

.conversation-compose .send {
  background: transparent;
  border: 0;
  cursor: pointer;
  flex: 0 0 auto;
  margin-left: 8px;
  margin-right: 8px;
  padding: 0;
  position: relative;
  outline: none;
}

.conversation-compose .send .circle {
  background: #008a7c;
  border-radius: 50%;
  color: #fff;
  position: relative;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.conversation-compose .send .circle i {
  font-size: 24px;
  margin-left: 5px;
}

.preview-footer {
  font-size: 11px;
  color: rgba(0, 0, 0, .45);
  word-wrap: break-word;
  white-space: pre-wrap;
}

.preview-body {
  font-size: 12px;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.row-button {
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
}

.col-button {
  background-color: #fff;
  border-radius: 7.5px;
  box-shadow: 0 1px 0.5px rgb(0 0 0 / 15%);
  box-sizing: border-box;
  flex-grow: 1;
  margin: 2px 0 0 2px;
  min-width: calc(50% - 2px);
  color: #00a5f4;
  font-size: 14px;
  height: 34px;
  line-height: 20px;
  padding: 0 16px;
  white-space: pre-wrap;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
}
/* Small Screens */

@media (max-width: 768px) {
  .marvel-device.nexus5 {
    border-radius: 0;
    flex: none;
    padding: 0;
    max-width: none;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  .marvel-device > .screen .chat {
    visibility: visible;
  }

  .marvel-device {
    visibility: hidden;
  }

  .marvel-device .status-bar {
    display: none;
  }

  .screen-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .conversation {
    height: calc(100vh - 55px);
  }
  .conversation .conversation-container {
    height: calc(100vh - 120px);
  }
}
.input-new-tag {
  width: 90px;
}
.header-video-filled {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
video {
  object-fit: contain;
  max-width: 300px;
}
</style>
